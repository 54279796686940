<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols='12'
            class='mb-md-0 mb-2'
          >
            <router-link
              :to="{ name: 'real-estate-not-licensed' }"
              class='btn btn-primary w-100 main-btn-link'
              custom
            >
              <img
                class='img-fluid mb-1'
                style='width:100%; max-width: 95px;'
                src='@/assets/images/real-estate-not-licensed.svg'
                alt='image'
              >
              <span
                class='text-capitalize'
              >{{ $t('not_licensed_real_estate') }}</span>
            </router-link>

          </b-col>

          <b-col
            cols='12'
            md='6'
            class='my-md-2'
          >
            <router-link
              :to="{ name: 'real-estate-leave-on-us-private' }"
              class='btn w-100 main-btn-link'
              style='background-color: #5ec8e8;color: white'

              custom
            >
              <img
                class='img-fluid mb-1'
                style='width:100%; max-width: 95px;'
                src='@/assets/images/leave-it-on-us.svg'
                alt='image'
              >
              <span
                class='text-capitalize'
                role='link'
              >{{ $t('real estate leave on us private') }}</span>
            </router-link>

          </b-col>

          <b-col
            cols='12'
            md='6'
            class='my-md-2'
          >
            <router-link
              :to="{ name: 'real-estate-leave-on-us' }"
              class='btn w-100 main-btn-link'
              style='background-color: #b4b7bd;color: white'

              custom
            >
              <img
                class='img-fluid mb-1'
                style='width:100%; max-width: 95px;'
                src='@/assets/images/leave-it-on-us.svg'
                alt='image'
              >
              <span
                class='text-capitalize'
                role='link'
              >{{ $t('real estate leave on us') }}</span>
            </router-link>

          </b-col>

        </b-row>
      </b-card-body>
    </b-card>

  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'


export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'real-estate-options',
      singularName: 'real-estate-options',
    }
  },


  created() {
  },
}
</script>

<style scoped>
.main-btn-link {
  height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  flex-direction: column;
}
</style>